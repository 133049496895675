export const partnerLogos = [
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/abej_logo_tzoa1i.svg',
    alt: 'Abej Solidarité',
    link: 'https://abej-solidarite.fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/french_tech_logo_g1zwwo.svg',
    alt: 'La French Tech',
    link: 'https://lafrenchtech.com/fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/antropia_ESSEC_logo_tnnfd8.png',
    alt: 'Antropia ESSEC',
    link: 'https://antropia-essec.fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/hodefi_logo_ggjkvn.png',
    alt: 'Hodéfi',
    link: 'https://www.hodefi.fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/ile_de_la_solidarite_logo_apkm7z.svg',
    alt: 'L\'île de la solidarité',
    link: 'https://www.liledesolidarite.fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/HDFID_logo_grbmbx.png',
    alt: 'Hauts-de-France innovation developpement',
    link: 'https://www.hautsdefrance-id.fr/'
  },
  {
    url:
      'https://res.cloudinary.com/cryptr/image/upload/v1637526715/Stirrup/partners_logos/euratechnologie_logo_mh8c9e.svg',
    alt: 'Euratechnologies',
    link: 'https://www.euratechnologies.com/'
  },
]