import * as React from "react"
import Container from "../elements/container"

const SectionH2Simple = ({title, description, children, paddingDesktop, backgroundColor}) => (
  <Container backgroundColor={backgroundColor} paddingDesktop={paddingDesktop} >
    <div className="flex flex-col text-center">
      <h2 className="text-skin-indigo font-bold text-2xl">{title}</h2>
      {description &&
        <p className="mt-5 max-w-prose mx-auto text-lg text-skin-base">{description}</p>   
      }
      {children}
    </div>
  </Container>
)

export default SectionH2Simple
