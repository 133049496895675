import * as React from "react"
import { ContactButton } from "../elements/button"

const Card = ({title, description, btnText}) => (
  <div className="flex-1 bg-skin-cold p-10 flex flex-col justify-start rounded-lg">
    <div className="block mt-2">
      <h3 className="mb-10 text-3xl font-semibold text-transparent bg-gradient-to-r bg-clip-text from-skin-pink via-skin-orange">
        {title}
      </h3>
      <p className="mt-3 mb-10 text-lg text-skin-base">
        {description}
      </p>
      {/* <ButtonDefault text={btnText} size="regular" path="/" /> */}
      <ContactButton text="Contactez-nous" size="regular" />
    </div>
  </div>
)

export default Card