import * as React from "react"
import Container from "../components/elements/container"
import Card from "../components/elements/card"

const Personas = () => (
  <Container backgroundColor="bg-white" containerType="section">
    <div className="md:mt-10 lg:mt-32 relative">
      <div className="max-w-3xl mx-auto space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">
        <div className="md:flex md:flex-row md:items-end">
          {/* Mobile illustration */}
          <div className="absolute -mt-6 -ml-4 left-0 sm:hidden">
            <img
              src="https://res.cloudinary.com/cryptr/image/upload/v1637599556/Stirrup/key_persona_illustration_y4xe2j.png"
              alt="Key"
            />
          </div>
          {/* Tablet & Desktop illustration */}
          <div className="hidden md:block md:w-1/2 md:mr-11 lg:w-auto lg:-mt-28 lg:absolute lg:left-0 lg:top-0">
            <img
              src="https://res.cloudinary.com/cryptr/image/upload/v1637597624/Stirrup/stirrup_persona_illustration_A_tmhaki.png"
              alt="Propriétaires"
            />
          </div>
          <div className="md:w-1/2 lg:w-auto">
            <Card 
              title="Propriétaires" 
              description="Stirrup propose aux propriétaires privés de valoriser leurs logements vacants grâce à un contrat de prêt de logement souple et de courte durée (6 mois). Les économies seront immédiates et différées: entretien, assurance, sécurisation par l’occupation, taxe sur les logements vacants." 
              btnText="M'inscrire"
            />
          </div>
        </div>
        <div className="md:flex md:flex-row md:items-end">
          {/* Mobile illustration */}
          <div className="absolute -mt-6 -mr-4 right-0 sm:hidden">
            <img
              src="https://res.cloudinary.com/cryptr/image/upload/v1637599560/Stirrup/house_persona_illustration_t6g1r2.png"
              alt="House"
            />
          </div>
          {/* Tablet & Desktop illustration */}
          <div className="hidden md:block md:w-1/2 md:order-last md:ml-11 lg:w-auto lg:-mt-28 lg:absolute lg:top-0 lg:right-0">
            <img
              src="https://res.cloudinary.com/cryptr/image/upload/v1637597624/Stirrup/stirrup_persona_illustration_B_i5zv3t.png"
              alt="Collectivités"
            />
          </div>
          <div className="md:w-1/2 md:order-first lg:w-auto">
            <Card 
              title="Collectivités" 
              description="Les collectivités dépensent en moyenne 17 000 € / an pour l’hébergement des sans-abri, Stirrup leur permet d’économiser les coûts d’hébergement d’urgence très onéreux, comme les nuitées d’hôtels pour les familles et des coûts indirects de santé, de sécurité, etc." 
              btnText="M'inscrire"
            />
          </div>
        </div>
      </div>
    </div>
  </Container>
)

export default Personas
