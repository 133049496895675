import * as React from "react"

export const homeHero = {
  subhead:'Un patrimoine foncier rentable et solidaire',
  title: {
    span_1: 'L’alternative solidaire',
    span_2: 'aux logements vacants'
  },
  description: 'Plateforme de mise en relation qui sécurise le prêt de logements vacants à des associations d’entraide pour une période tremplin',
  contact_button: 'Contactez-nous',
  illustration: {
    src: 'https://res.cloudinary.com/cryptr/image/upload/v1637516196/Stirrup/stirrup-hero-illustration_shnjmh.jpg',
    alt: 'Stirrup superhero welcomes a family'
  }
}

export const homeGridList = {
  title: 'Pourquoi Stirrup',
  items: [
    {
      title: '+ 50 % en 10 ans',
      description: 'Le nombre de sans-domicile s’est accu de 50 % entre 2001 et 2012.'
    },
    {
      title: '150 000 Sans-Abri',
      description: 'Il y a plus de 150 000 sans-abri et plus de 4 millions de mal logés en France.'
    },
    {
      title: '6 sur 10',
      description: 'C’est le nombre d’appels au 115 sans solution !'
    },
    {
      title: '3 millions',
      description: 'Alors qu’il y a plus de 3 millions de logements vacants en France !'
    },
    {
      title: '2500 € / an',
      description: 'C’est le coût moyen de la vacance d’un logement (dégradations, squat, ...)'
    },
  ]
}

export const homeTestimonial = {
  title:'À propos',
  testimonial_text: 'STIRRUP c’est l’histoire de ma rencontre en 2017 avec une famille de demandeurs d’asile qui vivait à la rue, sous une tente, lors d’une distribution alimentaire. Des liens d’amitié se sont créés. La maman, Aferdita, attendait un heureux événement pour Noël... Impensable que ce bébé ne naisse à la rue ! Il fallait leur trouver une solution de mise à l’abri.',
  author : {
    image: <img
      src="https://res.cloudinary.com/cryptr/image/upload/v1637610329/Stirrup/stirrup_delphine_barthe_ceo_esfq5m.jpg"
      alt="Delphine Barthe - Stirrup CEO"
    />,
    name: 'Delphine Barthe',
    job: 'CEO & Super Woman',
    social_network: [
      {
        name: 'Linkedin',
        icon: 
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
          <path fillRule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" />
        </svg>, 
        link: 'https://www.linkedin.com/in/delphinebarthe/'
      }
    ]
  }
}

export const homeFooter = {
  title: {
    span_1: 'Vous êtes une entreprise ? un bailleur social ? une collectivité ? un propriétaire privé ?',
    span_2: 'Vous avez un logement ou bâtiment vacant ?'
  },
  contact_button: 'Rentabilisez solidaire'
}