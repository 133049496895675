import * as React from "react"
const KpiBlock = ({title, number, index}) => (
  <div className={`p-1 bg-gradient-to-b ${index === 0 ? "from-skin-pink" : "from-skin-orange"} via-pink-200 to-white rounded-xl`}>
    <div className="bg-white flex flex-col rounded-t-lg p-7 text-center">
      <dt className={`text-lg leading-6 font-bold ${index === 0 ? "text-skin-pink" : "text-skin-orange"} uppercase`}>
        {title}
      </dt>
      <dd className="mt-3 text-5xl font-normal text-skin-default">
        {number}
      </dd>
    </div>
  </div>
)

export default KpiBlock