import * as React from "react"
import Container from "../elements/container"
import KpiBlock from "../elements/kpi_block"

const SectionKpis = ({kpiItems, backgroundColor, paddingDesktop}) => {
  return (
    <Container backgroundColor={backgroundColor} paddingDesktop={paddingDesktop} >
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-4xl mx-auto">
          <dl className={`${kpiItems.length === 1 && "max-w-lg mx-auto"} rounded-lg grid sm:grid-cols-${kpiItems.length} gap-16`}>
            {kpiItems.map((kpiItem, index) => {
              return (
                <KpiBlock key={index} index={index} title={kpiItem.title} number={kpiItem.number}/>
              )
            })}
          </dl>
        </div>
      </div>
    </Container>
    )
}

export default SectionKpis
