export const conceptKpis = [
  {
    title: 'Nombre de personnes logées',
    number: '21,368',
  }
]

export const kpis = [
  {
    title: 'Sorties positives',
    number: '21,368',
  },
  {
    title: 'Familles logées',
    number: '19,243',
  },
]