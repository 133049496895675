import * as React from "react"

const SectionLogos = ({logos, title}) => (
  <div className="lg:space-y-8">
    {title && (
      <div className="mb-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white font-bold text-skin-indigo">
              {title}
            </span>
          </div>
        </div>
      </div>
    )}
    <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
      {logos.slice(0, 4).map((logo, index) => {
        return (
          <div key={index} className="col-span-1 flex justify-center items-center" >
            <a href={logo.link} target="_blank" rel="noreferrer" >
              <img className="mt-8 mb-0" src={logo.url} alt={logo.alt} />
            </a>
          </div>
        )
      })}
    </div>
    <div className="grid grid-cols-2 gap-0 md:grid-cols-2 lg:grid-cols-3">
      {logos.slice(4, 7).map((logo, index) => {
        return (
          <div key={index} className={`${index === 2 ? "col-span-2 lg:col-span-1" : "col-span-1"} flex justify-center items-center`}>
            <a href={logo.link} target="_blank" rel="noreferrer" >
              <img className="mt-8 mb-0" src={logo.url} alt={logo.alt} />
            </a>
          </div>
        )
      })}
    </div>
  </div>
)

export default SectionLogos
