import * as React from "react"
import Container from "../elements/container"

const SectionH2Base = ({children, backgroundColor}) => (
  <Container backgroundColor={backgroundColor}>
    <div className="flex flex-col items-center">
      <div className="text-center">
        <h2 className="lg:pb-8 text-3xl font-extrabold text-transparent bg-gradient-to-r bg-clip-text from-skin-pink via-skin-red to-skin-orange sm:text-4xl">
          Concept
        </h2>
        <div className="mt-5 max-w-prose mx-auto text-lg text-skin-base">
          <p>Stirrup permet le prêt de logements vacants à des personnes ayant besoin d’un toit : femmes victimes de violences, jeunes sortis de l’aide à l’enfance, réfugiés sans abris ...</p>
          <p>La plateforme Stirrup facilite et sécurise la mise en relation entre propriétaires fonciers et structures d’entraide. <br/>Pendant une période de 6 mois, le propriétaire de logements vacants (entreprise privée, bailleur, collectivité, particulier ...) prête gratuitement son bien immobilier à une structure reconnue d’intérêt général. En échange, celle-ci lui fournit un reçu fiscal permettant une réduction d’impôts jusqu’à 66% du montant des loyers offerts.</p>
          <p>Durant cette période tremplin, les associations effectuent un accompagnement 360° : suivi mensuel du logement, garantie du paiement des charges) et accompagnement social des personnes logées (retour à l’emploi et au logement pérenne)</p>
          <p>Stirrup encadre la relation en mettant à disposition le cadre contractuel sécurisé.</p>
        </div>
      </div>
      <div className="py-12">
        <img
          src="https://res.cloudinary.com/cryptr/image/upload/v1637529353/Stirrup/stirrup-concept-illustration_lfusao.jpg"
          alt="A landlord gives the keys"
        />
      </div>
      {children}
      <div className="text-center">
        <div className="mt-5 max-w-prose mx-auto text-lg text-skin-base">
          <p>Stirrup signifie Etrier en anglais : pour mettre le pied à l’Etrier. <br/>Notre mission est de faire la différence pour des personnes connaissant une rupture de parcours ou un accident de vie : incendie d’une maison, femmes victimes de violences, jeunes sortant de l’aide sociale à l’enfance, réfugiés, ...</p>
          <p className="mb-0">Le projet a démarré en 2017 de façon solidaire et s’est professionnalisé en 2018 pour proposer aujourd’hui une solution clé en main pour les propriétaires et pour les associations d‘entraide.</p>
        </div>
      </div>
    </div>
  </Container>
)

export default SectionH2Base
