import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SectionH2Base from "../components/sections/section_h2_base"
import Seo from "../components/seo"
import GridList from "../components/grid_list"
import Personas from "../components/personas"
import Testimonial from "../components/testimonial"
import SectionLogos from "../components/sections/section_logos"
import { partnerLogos } from "../data/partner_logos"
import SectionH2Simple from "../components/sections/section_h2_simple"
import SectionKpis from "../components/sections/section_kpis"
import { conceptKpis } from "../data/kpis"
import { 
  homeHero, 
  homeTestimonial,
  homeGridList,
  homeFooter
} from "../data/home"


const IndexPage = () => {
  return (
    <Layout dataFooter={homeFooter}>
      <Seo title="Stirrup" />
      <Hero
        subhead={homeHero.subhead}
        title={homeHero.title}
        description={homeHero.description}
        contactButton={homeHero.contact_button}
        illustration={homeHero.illustration}
        backgroundColor="bg-white"
      >
        <SectionLogos title="Nos partenaires :" logos={partnerLogos}/>
      </Hero>
      <SectionH2Base backgroundColor="bg-skin-cold">
        <GridList 
          title={homeGridList.title}
          items={homeGridList.items}
        />
      </SectionH2Base>
      <SectionKpis 
        kpiItems={conceptKpis}
        paddingDesktop="top"
        backgroundColor="bg-white"
      />
      <SectionH2Simple 
        title="Stirrup met en relation les propriétaires de logements vacants et les associations d’entraide"
        description="Une relation gagnant gagnant au service des personnes ayant besoin d’un coup de pouce pour retrouver un confort de vie"
        paddingDesktop="top"
        backgroundColor="bg-white"
      >
        <Personas />
      </SectionH2Simple>
      <Testimonial 
        title={homeTestimonial.title}
        testimonial_text={homeTestimonial.testimonial_text}
        author={homeTestimonial.author}
        backgroundColor="bg-gradient-to-r from-skin-pink-light to-skin-orange-light"
      />
    </Layout>
  )
}

export default IndexPage
